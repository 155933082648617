import React, { useLayoutEffect, useRef } from "react";
import CardEvent from "../components/CardEvent";
import { Helmet } from 'react-helmet';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Hero from "../components/Hero";
import heroImage from "../img/heros/IMG_10.jpg";
gsap.registerPlugin(ScrollTrigger);

const Gigs = () => {
  const cards = useRef([]);
  cards.current = [];

  useLayoutEffect(() => {
    cards.current.map((item, index) => {
      gsap.fromTo(item,
        {
          opacity: 0
        },
        {
          scrollTrigger: {
            trigger: item,
            start: "-100 70%",
            end: "bottom top",
          },
          duration: 2,
          opacity: 1,
          ease: "power2",
        }
      );
    });
  });

  const card = (item) => {
    if (item) {
      cards.current.push(item);
    }
  };
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://skunksters.com/Gigs" />
      </Helmet>
      <Hero image imageSrc={heroImage} />
      <div className="app__background">
        <div className="app__layout">
          <h1 className="card__title--large">Follow the scent near you</h1>
          <div ref={card}>
            <CardEvent
              date="Sat, 1 Feb at 21:00"
              title="Kalashnikov Skunk at England's Glory"
              location="See venue details"
              locationLink="https://www.facebook.com/events/2357275644616609/"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="Sat, 29 Mar at 21:30"
              title="The Black Star · Stourport"
              location="See venue details"
              locationLink="https://www.theblackstar.co.uk/"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="Fri, 16 May at 21:00"
              title="live at the Golden Cross Inn"
              location="See venue details"
              locationLink="https://www.facebook.com/events/939325234467862/"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="Fri, 18 Jul at 18:00"
              title="Upton Blues Festival 2025"
              location="See venue details"
              locationLink="https://www.facebook.com/events/1420248332247708"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="Fri, 22 Aug at 21:00"
              title="The Black Star · Stourport"
              location="See venue details"
              locationLink="https://www.theblackstar.co.uk/"
            />
          </div>
          <div ref={card}>
            <CardEvent
              date="Fri, 31 Oct at 20:30"
              title="Halloween with Kalashnikov Skunk"
              location="See venue details"
              locationLink="https://www.facebook.com/events/2799907626851117/"
            />
          </div>

          <div ref={card}>
            <CardEvent
              date="Looking for more events?"
              title="See our facebook events"
              location="Facebook events page"
              locationLink="https://www.facebook.com/kalashnikov.skunk/events"
              facebook="true"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gigs;
